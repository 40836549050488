body {
	font-size: calc(12px + 10 * ((100vw - 320px) / (1920 - 320)));
	line-height: 1.4rem;
	/* content-visibility: auto; */
	/* line-height: calc(1.6em + 0.4 * ((100vw - 320px) / (1920 - 320))); */
	margin: 0;
	padding: 0;
	font-family: 'Hind', 'Roboto', 'PT Sans', 'Muli', -apple-system,
		BlinkMacSystemFont, 'Segoe UI', 'Noto Sans', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

* {
	box-sizing: border-box;
}

img {
	max-width: 100%;
	max-height: 100%;
	object-fit: cover;
}

a,
a:visited {
	color: currentColor;
	text-decoration: none;
	border-bottom: solid 2px #00000000;
}

a:hover {
}

audio,
canvas,
progress,
video {
	display: inline-block;
	content-visibility: auto;
}

button {
	border-style: none;
	cursor: pointer;
	/* box-shadow: 1px 2px 4px rgba(20, 20, 20, 0.12); */
	padding: 4px 10px;
}

button:hover {
	box-shadow: 2px 4px 8px rgba(20, 20, 20, 0.16);
}

audio:not([controls]) {
	display: none;
	height: 0;
}

ul,
ul > li {
	list-style: none;
	padding: unset;
}

ol,
ol > li {
	padding: unset;
}

legend {
	background-color: #000;
	color: #fff;
	padding: 3px 6px;
}

* {
	scrollbar-color: royalblue;
	scrollbar-track-color: antiquewhite;
}

::-webkit-scrollbar {
	width: 8px;
	height: 10px;
}

::-webkit-scrollbar-thumb {
	background: hsl(225, 73%, 57%); /* linear-gradient(#16df84, #162432); */
	border-radius: 4px;
}

.bg_WhiteSmoke {
	background-color: #f5f5f5;
}

.pointer {
	cursor: pointer;
}

.debugger {
	background-color: antiquewhite;
	width: 100%;
	margin: 4vh auto;
	padding: 1rem;
	text-align: start;
}

.external_Link {
	color: salmon;
}

.material_icons {
	font-size: inherit;
	color: rebeccapurple;
}

.post:hover {
	background: #16df8480;
}

.highlight {
	padding: 0 2px 0 2px;
	border-bottom: 2px solid #16df84;
	box-shadow: inset 0 -3px 0 #16df84;
}

.outer_link,
.outer_link:visited {
	color: hsl(219, 81%, 59%);
	font-size: 0.8rem;
	padding: 0.4rem 0;
	text-decoration: underline;
}

.fb,
.fb:visited {
	color: royalblue;
	border-color: royalblue;
}

.fb:hover {
	color: whitesmoke;
	background-color: royalblue;
}

.insta,
.insta:visited {
	color: #e92b56;
	border-color: #e92b56;
}

.insta:hover {
	color: whitesmoke;
	background-color: #e92b56;
}

.twit,
.twit:visited {
	color: #0089ec;
	border-color: #0089ec;
}

.twit:hover {
	color: whitesmoke;
	background-color: #0089ec;
}

.yt,
.yt:visited {
	color: crimson;
	border-color: crimson;
}

.yt:hover {
	color: whitesmoke;
	background-color: crimson;
}

.snap,
.snap:visited {
	color: yellow;
	border-color: yellow;
}

.snap:hover {
	color: whitesmoke;
	background-color: rgba(255, 255, 0, 0.85);
}

.in,
.in:visited {
	color: #0079e4;
	border-color: #0079e4;
}

.in:hover {
	color: whitesmoke;
	background-color: #0079e4;
}

.pad_top_4 {
	padding-top: 4px;
}

.pad_bot_4 {
	padding-bottom: 4px;
}

.marg_top_4 {
	margin-top: 4px;
}

.pad_top_10 {
	padding-top: 10px;
}

.marg_top_10 {
	margin-top: 10px;
}

@media only screen and (min-width: 481px) {
	.debugger {
		width: 80%;
		padding: 2rem;
		text-align: start;
	}
}

.test_button {
	margin: 1vh 2vh;
	padding: 1vh 2vh;
	background-color: crimson;
	color: whitesmoke;
	font-size: 1rem;
	font-family: 'Roboto Slab';
	font-weight: 700;
	letter-spacing: 1.2px;
}

.WARN {
	color: crimson;
	font-size: small;
	margin: 4px 0;
	line-height: 1.25em;
}
