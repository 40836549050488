:root {
	--col_pep_1: #5ce1e6;
	--col_pep_1_15: rgba(92, 225, 230, 0.15);
	--col_pep_1_25: rgba(92, 225, 230, 0.25);
	--col_pep_1_30: rgba(92, 225, 230, 0.3);
	--col_pep_1_50: rgba(92, 225, 230, 0.5);
	--col_pep_1_70: rgba(92, 225, 230, 0.7);
	--col_pep_1_75: rgba(92, 225, 230, 0.75);
	--col_pep_1_90: rgba(92, 225, 230, 0.9);
	--col_pep_2: #87fff4;
	--col_pep_2_15: rgba(133, 255, 245, 0.15);
	--col_pep_2_25: rgba(133, 255, 245, 0.25);
	--col_pep_2_30: rgba(133, 255, 245, 0.3);
	--col_pep_2_50: rgba(133, 255, 245, 0.5);
	--col_pep_2_70: rgba(133, 255, 245, 0.7);
	--col_pep_2_75: rgba(133, 255, 245, 0.75);
	--col_pep_2_90: rgba(133, 255, 245, 0.9);
	--col_pep_3: #58aade;
	--col_pep_3_15: rgba(88, 170, 222, 0.15);
	--col_pep_3_25: rgba(88, 170, 222, 0.25);
	--col_pep_3_30: rgba(88, 170, 222, 0.3);
	--col_pep_3_50: rgba(88, 170, 222, 0.5);
	--col_pep_3_70: rgba(88, 170, 222, 0.7);
	--col_pep_3_75: rgba(88, 170, 222, 0.75);
	--col_pep_3_90: rgba(88, 170, 222, 0.9);

	--col_red_deep: #ed5063;
	--col_blue_deep: #004bfe;
	--col_deep_blue: #004bfe;
	--col_green_deep: #00bb5d;
	--col_red: #f18390;
	--col_red_20: rgba(241, 131, 144, 0.5);
	--col_blue: #40acff;
	--col_green: #4ace8c;
	--col_blue_link: #4f86f7;

	--grad_pep_12: linear-gradient(50deg, var(--col_pep_1), var(--col_pep_2));
	--grad_pep_21: linear-gradient(50deg, var(--col_pep_2), var(--col_pep_1));
	--grad_pep_13: linear-gradient(50deg, var(--col_pep_1), var(--col_pep_3));
	--grad_pep_31: linear-gradient(50deg, var(--col_pep_3), var(--col_pep_1));
	--grad_pep_23: linear-gradient(50deg, var(--col_pep_2), var(--col_pep_3));
	--grad_pep_32: linear-gradient(50deg, var(--col_pep_3), var(--col_pep_2));

	--nav_height: 64px;
	--nav_height_adjust: -64px;
	--nav_line_height: 48px;
	--head_background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0.9),
		rgba(0, 0, 0, 0.6),
		rgba(0, 0, 0, 0)
	);
	--nav_padding: 0 8px;
	--nav_grid_cols: 100px auto 160px;
	--nav_grid_rows: var(--nav_line_height) var(--nav_line_height);
	--nav_grid_areas: 'logo . propowner' 'links links links';

	--logo_height: 40px;
	--logo_margin: 4px;

	--avatar_height: 44px;

	--hero_height_large: 60vh;
	--hero_height_mid: 60vh;
	--hero_height_small: 60vh;
	--width_cont_maxwidth: 100%;
	--width_cont_padding: 2em 1em;

	--head_margin: 4px 0;
	--head_font_size: 1.8rem;
	--head_font_size_mini: 1rem;
	--head_line_height: 1.2em;
	--head_font_bold: 700;
	--head_font_regular: 500;
	--head_font_thin: 300;

	--links_container_width: 100%;
	--links_container_justify: stretch;

	--nav_link_width_max: 100px;
	--nav_link_fontsize: 0.8rem;
	--nav_link_margins: 0 4px;

	--nav_link_contact_width: 100%;
	--nav_link_email_width: 100%;
	--nav_email_align: left;

	--foot_flex_dir: column;
	--foot_flex_padding: 4vh 2vh;
	--foot_list_width: 100%;
	--foot_detail: 'f_comp f_comp f_comp' 'f_links f_about f_extras';
	--foot_grid_row: '280px 320px';
	--foot_font_size: 0.8rem;

	--page_title_font_size: 2.5rem;
	--page_title_line_height: 1.1em;

	--footer_company_details_margin: 0 auto;
}

.app {
	min-height: 100vh;
	font-size: 1rem;
	background-color: #eee;
	color: #444;
}

.nav_bar {
	width: 100%;
	height: var(--nav_height);
	padding: var(--nav_padding);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;

	display: flex;
	/* flex-direction: column; */
	/* flex-wrap: wrap; */
	justify-content: space-between;
	align-items: center;
	justify-items: center;
	justify-content: space-between;

	background: var(--head_background);
	/* background-color: #282c34; */
	color: #f2f2f2;
	font-size: 1rem;
	/* box-shadow: 0 -12px 10px hsla(0, 0%, 10%, 0.2); */
	/* box-shadow: 0 2px 4px rgba(34, 34, 34, 0.1); */
	z-index: 990;
}

.app_title_logo {
	margin: 0;
	padding: 0;
	height: 48px;
	width: 112px;
}

.app_logo {
	pointer-events: none;
	margin: 0;
	object-fit: contain;
}

.app_title {
	font-size: 1.2rem;
	font-weight: 700;
}

.page_container {
	width: 100%;
	max-width: 1200px;
	min-height: 80vh;
	margin: 0 auto;
	padding: 0 0.5rem;
}

.page_title {
	font-size: var(--page_title_font_size);
	line-height: var(--page_title_line_height);
	margin: 0.8rem 0 0.5rem;
	padding: 0;
	font-weight: 900;
	font-family: 'Poppins';
	color: #222;
	/* text-transform: capitalize; */
}

.page_subtitle {
	font-family: 'Poppins';
	font-size: 1.45rem;
	margin: 0rem 0 1rem;
	padding: 0;
	font-weight: 500;
}

/* @media (prefers-reduced-motion: no-preference) {
	.app_logo {
		animation: App-logo-spin infinite 10s linear;
	}
} */

.app_link {
	color: hsla(193, 95%, 68%, 1);
	text-decoration: underline;
}

.highlighter {
	text-decoration: underline;
	color: hsla(160, 90%, 24%, 1);
	font-weight: 500;
}

.note {
	margin: 1rem 0 0.5rem;
	font-size: 0.8rem;
	line-height: 1.5em;
}

.helper {
	max-width: 50%;
	margin: unset;
	font-size: 0.8rem;
}

.counter {
	display: inline-block;
	font-weight: 700;
	line-height: 1em;
	min-height: 1.4em;
	min-width: 1.5em;
	margin: 4px 8px;
	padding: 0.4em 0.25em 0.25em 0.25em;
	text-align: center;
	border-radius: 2em;
	background-color: #fff;
	box-shadow: 2px 2px 4px hsla(0, 0%, 7%, 0.18);
	/* border: solid 2px red; */
}

.btn_logout {
	padding: 8px 12px;
	border-radius: 4px;
	background-color: var(--col_red_deep);
	color: whitesmoke;
	font-weight: bold;
}

.form_debugger {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.form_debugger > details {
	margin-right: 4px;
}

@media only screen and (min-width: 375px) {
	:root {
		--page_title_font_size: 3rem;
		--page_title_line_height: 1.2em;
	}
}

@media only screen and (min-width: 481px) {
	:root {
		--nav_height: 64px;
		--nav_height_adjust: -64px;
		--head_background: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0.8),
			rgba(0, 0, 0, 0)
		);

		--logo_height: 48px;
		--logo_margin: 8px;
		--avatar_height: 42px;
		--hero_height_large: 360px;
		--hero_height_mid: 320px;
		--hero_height_small: 320px;

		--head_margin: 8px 0 4px;
		--head_font_size: 2.5rem;
		--head_font_size_mini: 0.9rem;
		--head_line_height: 1.2em;
		--head_font_bold: 700;
		--head_font_regular: 500;
		--head_font_thin: 300;

		--width_cont_maxwidth: 1200px;
		--width_cont_padding: 1em 2em;

		--hov_def_duration: 0.3s;
		--hov_def_transition_ease: ease-in-out;

		--nav_padding: 0 2vh;
		--nav_grid_cols: 160px 1fr 350px 160px;
		--nav_grid_rows: 1fr;
		--nav_grid_areas: 'logo . links propowner';

		--links_container_width: auto;
		--links_container_justify: end;
		--nav_link_width_max: auto;
		--nav_link_fontsize: 0.9rem;
		--nav_link_margins: 0 20px;
		--nav_link_contact_width: auto;
		--nav_link_email_width: auto;
		--nav_email_align: left;

		--foot_flex_dir: row;
		--foot_detail: 'f_comp f_links f_about f_extras';
		--foot_grid_row: '360px';
		--foot_font_size: 1rem;
		--foot_flex_padding: 4vh 8vh;
		--foot_list_width: 60%;

		--footer_company_details_margin: 0 auto 0 2rem;
	}
}

@media only screen and (min-width: 1200px) {
	:root {
		--foot_flex_padding: 4vh 12%;
		--page_title_font_size: 4rem;
		--page_title_line_height: 1.2em;
	}
}

@media only screen and (min-width: 1401px) {
	:root {
		--foot_flex_padding: 4vh 12%;
	}
}

@media only screen and (min-width: 1601px) {
	:root {
		--foot_flex_padding: 4vh 16%;
	}
}
